<div *ngIf="headerIcon" class="app-card-header-icon text-bg-primary">
    <mat-icon>{{headerIcon}}</mat-icon>
</div>
<mat-card>
    <button *ngIf="hasCloseButton"
            class="close"
            mat-icon-button
            (click)="tryClose()"
            tabindex="-1"><mat-icon>close</mat-icon></button>

    <mat-card-title *ngIf="headerIcon || headerTitle">
        {{headerTitle}}
    </mat-card-title>

    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>

    <mat-card-footer>
        <mat-spinner *ngIf="loading" diameter="40" color="primary" class="mb-4"></mat-spinner>

        <ng-content select="[app-card-footer]"></ng-content>

        <app-button-list *ngIf="!loading"
                         [actionsPosition]="actionsPosition"
                         [actionsInverseOrder]="actionsInverseOrder"
                         [hMargin]="true">
            <ng-content select="[app-card-footer-action]"></ng-content>
        </app-button-list>

        <ng-content select="[app-card-footer-last]"></ng-content>
    </mat-card-footer>
</mat-card>